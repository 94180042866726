import axios from "axios";
import authHeader from "./auth-header";
//const API_URL = 'http://localhost:8081/';
const API_URL = "https://api-unp.sacul.cloud/NeK9NFKk/admin/";
const UNSECURE_API_URL = "https://api-unp.sacul.cloud/admin/";
const SECURE_KEY = "o0XO*vG6^amgP^LjxzGOUwJTrnM4Ejfw76hiXHoE1w#GbpUqpM";

class AdminServices {
  async getUsersList() {
    var params = {
      secureKey: SECURE_KEY,
    };
    try {
      const response = await axios.post(API_URL + "getUsers", params, {
        headers: authHeader(),
      });
      return response.data.data;
    } catch (error) {
      return "NOT_OK";
    }
  }

  async blockUser(useruuid, username) {
    var params = {
      secureKey: SECURE_KEY,
      username: username,
      useruuid: useruuid,
    };
    try {
      const response = await axios.post(API_URL + "blockUser", params, {
        headers: authHeader(),
      });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error };
    }
  }

  async unblockUser(useruuid, username) {
    var params = {
      secureKey: SECURE_KEY,
      username: username,
      useruuid: useruuid,
    };
    try {
      const response = await axios.post(API_URL + "unblockUser", params, {
        headers: authHeader(),
      });
      return { success: true };
    } catch (error) {
      return { success: false, error: error };
    }
  }

  async getApplicationsList() {
    var params = {
      secureKey: SECURE_KEY,
    };
    try {
      const response = await axios.post(API_URL + "getApplications", params, {
        headers: authHeader(),
      });
      return response.data.data;
    } catch (error) {
      return "NOT_OK";
    }
  }

  // async getNavigation(username, useruuid) {
  //   // get username and useruuid from localstorage
  //   var username = JSON.parse(localStorage.getItem("user")).username;
  //   var useruuid = JSON.parse(localStorage.getItem("user")).useruuid;
  //   var params = {
  //     username: username,
  //     useruuid: useruuid,
  //   };
  //   try {
  //     const response = await axios.post(
  //       UNSECURE_API_URL + "getNavigation",
  //       params,
  //       { headers: authHeader() }
  //     );
  //     if (response.data.success) {
  //       return response.data.navigation;
  //     } else {
  //       return "NOT_OK";
  //     }
  //   } catch (error) {
  //     return "NOT_OK";
  //   }
  // }

  async updateApplicationStatus(
    username,
    useruuid,
    status,
    rejectionreason,
    privateNotes,
    gamesOnboardedFor,
    gamesRejectionReason,
    externalGames,
    agentUsername,
    timestamp,
    gamesInterestedIn,
    rejectedOrRevokedGames,
    addRolesWithLilly,
    discordId
  ) {
    var params = {
      secureKey: SECURE_KEY,
      type: "applicationStatus",
      username: username,
      useruuid: useruuid,
      data: {
        status: status,
        rejectionReason: rejectionreason || "",
        lastUpdateBy: agentUsername,
        lastUpdateAt: timestamp,
        privateNotes: privateNotes || "",
        gamesOnboardedFor: gamesOnboardedFor,
        gamesRejectionReason: gamesRejectionReason || "undefined",
        externalGamesOnboardedFor: externalGames || null,
        gamesInterestedIn: gamesInterestedIn || null,
        rejectedOrRevokedGames: rejectedOrRevokedGames || null,
        addRolesWithLilly: addRolesWithLilly || null,
        discordId: discordId || null,
      },
    };
    try {
      const response = await axios.post(
        API_URL + "updateApplicationStatus",
        params,
        {
          headers: authHeader(),
        }
      );
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error };
    }
  }

  async getUserApplication(useruuid) {
    var params = {
      useruuid: useruuid,
    };
    try {
      const response = await axios.post(API_URL + "getApplication", params, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      return "NOT_OK";
    }
  }

  async getUserDiscordData(useruuid) {
    var params = {
      useruuid: useruuid,
    };
    try {
      const response = await axios.post(
        API_URL + "getUserDiscordData",
        params,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (error) {
      return "NOT_OK";
    }
  }

  async sendInterviewNotification(userDiscordId, agentDiscordId) {
    var params = {
      discordId: userDiscordId,
      agentDiscordId: agentDiscordId,
    };
    try {
      const response = await axios.post(
        API_URL + "discord/interviewNotification",
        params,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (error) {
      return "NOT_OK";
    }
  }

  async createThread(agentDiscordId, userDiscordId, userDiscordUsername) {
    var params = {
      agentDiscordId: agentDiscordId,
      userDiscordId: userDiscordId,
      userDiscordUsername: userDiscordUsername,
    };
    try {
      const response = await axios.post(
        API_URL + "discord/createThread",
        params,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async lillyInterview(agentDiscordId, userDiscordId, userDiscordUsername) {
    var params = {
      agentDiscordId: agentDiscordId,
      userDiscordId: userDiscordId,
      userDiscordUsername: userDiscordUsername,
    };
    try {
      const response = await axios.post(
        API_URL + "discord/lillyInterview",
        params,
        {
          headers: authHeader(),
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async getSettings() {
    try {
      const response = await axios.get(API_URL + "getSettings", {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      return "NOT_OK";
    }
  }

  async setMaintenanceMode(status) {
    var params = {
      status: status,
    };
    try {
      const response = await axios.post(API_URL + "setMaintenance", params, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
}

export default new AdminServices();
