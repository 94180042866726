<template>
  <div>
    <b-row>
      <b-col cols="12" lg="3">
        <b-button
          variant="primary"
          size="sm"
          class="mx-2 mt-3"
          @click="selectAllRows"
        >
          <span>Select All</span>
        </b-button>
        <b-button
          :disabled="!selected.length"
          variant="primary"
          size="sm"
          class="mx-2 mt-3"
          @click="clearSelected"
        >
          <span>Unselect All</span>
        </b-button>
        <b-button
          :disabled="!selected.length"
          variant="primary"
          size="sm"
          class="mx-2 mt-3"
          v-b-modal.bulkUpdatesModal
        >
          <span>Bulk Update</span>
        </b-button>
        <p class="text-right mt-6">{{ selected.length }} User(s) Selected</p>
      </b-col>
      <b-col cols="12" lg="4">
        <b-form-group
          v-model="sortDirection"
          label="Filter by"
          description="Leave all unchecked to use the search"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filter"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="Approved">Approved</b-form-checkbox>
            <b-form-checkbox value="New">New</b-form-checkbox>
            <b-form-checkbox value="Pending Interview"
              >Pending Interview</b-form-checkbox
            >
            <b-form-checkbox value="Rejected">Rejected</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="5">
        <b-form-group
          label="Search in the table"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          description="Can only be used if you don't have a filter"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filterSearch"
              type="search"
              placeholder="Type to Search"
              :disabled="filter"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filterSearch"
                @click="filterSearch = ''"
                variant="outline-primary"
              >
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :busy="isLoading"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter || filterSearch"
      :filter-included-fields="filterOn || null"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      stacked="md"
      show-empty
      @filtered="onFiltered"
      class="mt-24"
      selectable
      @row-selected="onRowSelected"
      select-mode="multi"
      ref="selectableTable"
    >
      <template #table-busy>
        <div class="text-center my-6">
          <b-spinner class="align-middle mr-12"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(status)="row">
        <b-badge
          v-if="row.value === 'Approved'"
          variant="success"
          class="mr-1"
          style="min-width: 100px"
        >
          {{ row.value }}
        </b-badge>
        <b-badge
          v-else-if="row.value === 'New'"
          class="mr-1"
          style="
            min-width: 100px;
            background-color: #5c5c5c;
            border-color: white;
            color: white;
          "
        >
          {{ row.value }}
        </b-badge>
        <b-badge
          v-else-if="row.value === 'Pending Interview'"
          variant="info"
          class="mr-1"
          style="min-width: 100px"
        >
          {{ row.value }}
        </b-badge>
        <b-badge v-else variant="danger" class="mr-1" style="min-width: 100px">
          {{ row.value }}
        </b-badge>
      </template>

      <template #cell(interestedIn)="row">
        <!--<b-row style="max-width: 400px">
          {{ row.value }}
        </b-row>-->
        {{ row.value }}
      </template>

      <template #cell(actions)="row">
        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              size="sm"
              @click="openApplicationBtn(row.item.data.useruuid)"
              class="mr-2"
            >
              Open
            </b-button>
            <!--<b-button variant="success" class="btn-ghost mx-2" size="sm">
              <img
                src="https://cdn.discordapp.com/avatars/971788258033950720/1a075120a63df1c881339d5e3b4b0c81.png?size=1024"
                alt="Lilly"
                class="avatar avatar-sm"
                style="max-height: 20px"
              />
              <span>Send Notification</span>
            </b-button>-->
          </b-col>
        </b-row>
      </template>
    </b-table>

    <b-row class="mt-16 align-items-center justify-content-end">
      <b-col class="hp-flex-none w-auto">
        <b-form-group label-for="per-page-select" label-size="sm" class="mb-0">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col class="hp-flex-none w-auto">
        <b-pagination
          align="end"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Bulk Updates Modal -->
    <b-modal id="bulkUpdatesModal" title="Bulk Updates" size="lg" hide-footer>
      <b-tabs class="mb-8">
        <b-tab title="Application Status" disabled>
          <b-row>
            <b-col cols="12" class="mt-16">
              <b-form-group
                label="Select a status"
                label-for="bulkUpdatesStatusSelect"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="bulkUpdatesStatusSelect"
                  v-model="bulk.applicationStatus.status"
                  :options="bulk.applicationStatus.options"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" align="end">
              <b-button
                variant="primary"
                class="mt-16"
                @click="bulkUpdateApplicationStatus()"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Discord Notifications" active>
          <b-row>
            <b-col cols="12" class="mt-16">
              <b-form-group
                label="Select a notification"
                label-for="bulkUpdatesNotificationSelect"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="bulkUpdatesNotificationSelect"
                  v-model="bulk.discordNotifications.notification"
                  :options="bulk.discordNotifications.options"
                ></b-form-select>
              </b-form-group>
              <b-form-textarea
                v-if="bulk.discordNotifications.notification === 'custom'"
                id="customNotification"
                v-model="bulk.discordNotifications.customMessage"
                placeholder="Enter a custom message"
                rows="3"
                max-rows="6"
                class="mt-8"
              ></b-form-textarea>
            </b-col>
            <b-col cols="12" align="end">
              <b-button
                variant="primary"
                class="mt-16"
                @click="bulkNotifiaction()"
              >
                Send
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal
      id="bulkUpdatesStatusModal"
      title="Bulk Updates Status"
      size="lg"
      hide-footer
      scrollable
    >
      <b-table :items="bulk.bulkStatus">
        <template #cell(status)="row">
          <div v-if="row.value == 'pending'" class="text-left">
            <b-spinner small variant="info"></b-spinner>
            <span class="ml-2">Sending...</span>
          </div>
          <div v-else-if="row.value == 'success'" class="text-left">
            <b-icon
              icon="check-circle-fill"
              variant="success"
              class="align-middle mr-12"
            ></b-icon>
            <strong>Success</strong>
          </div>
          <div v-else-if="row.value == 'error'" class="text-left">
            <b-icon
              icon="exclamation-circle-fill"
              variant="danger"
              class="align-middle mr-12"
            ></b-icon>
            <strong>Error</strong>
          </div>
          <div v-else class="text-left">
            <b-icon
              icon="exclamation-circle-fill"
              variant="danger"
              class="align-middle mr-12"
            ></b-icon>
            <strong>Unknown</strong>
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormSelect,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTextarea,
  BPagination,
  BModal,
  BToast,
  BAlert,
  BBadge,
  BTabs,
  BSpinner,
  BIcon,
  BProgressBar,
} from "bootstrap-vue";
import AdminServices from "../../../../../services/admin.services";
import BulkAdmin from "../../../../../services/bulk-admin.services";

export default {
  data() {
    return {
      isLoading: true,
      items: [],
      fields: [
        {
          key: "status",
          label: "Status",
        },
        {
          key: "createdAt",
          label: "Sumbitted At",
          sortable: true,
          sortDescFirst: true,
          sortDirection: "desc",
          formatter: (value, key, item) => {
            // value is a timestamp
            return (
              new Date(value * 1).toLocaleDateString() +
              " " +
              new Date(value * 1).toLocaleTimeString()
            );
          },
        },
        { key: "discordUsername", label: "Discord" },
        { key: "interestedIn", label: "Interested In" },
        { key: "handledBy", label: "Handled By" },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 15, 30, 100, { value: 10000, text: "Show all" }],
      sortBy: "createdAt",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
      filterSearch: null,
      selected: [],
      bulk: {
        action: "",
        applicationStatus: {
          status: null,
          options: [
            { value: "submitted", text: "Submitted" },
            { value: "pending interview", text: "Pending Interview" },
            { value: "approved", text: "Approved" },
            { value: "rejected", text: "Rejected" },
            { value: "migrated", text: "Migrated" },
          ],
        },
        discordNotifications: {
          notification: null,
          customMessage: null,
          options: [
            { value: "interview_reminder", text: "Interview reminder" },
            //{ value: "custom", text: "Custom Notification" },
          ],
        },
        bulkStatus: [],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormTextarea,
    BPagination,
    BModal,
    BToast,
    BAlert,
    BBadge,
    BTabs,
    BSpinner,
    BIcon,
    BProgressBar,
  },
  computed: {
    sortOptions() {
      return this.items.filter((item) => {
        let keep = true;

        // This is a basic equality filter. What I did in the actual code was to have an object with filter functions for each key. If a key was missing, it defaulted to straight equality.
        this.fieldKeys.forEach((key) => {
          keep =
            keep &&
            (this.selectVal[key] === undefined ||
              item[key] === this.selectVal[key]);
        });

        return keep;
      });
    },
    fieldKeys() {
      return Object.keys(this.items[0]);
    },
    options() {
      // This could be simplified if the select was it's own component.
      const options = {};
      this.fieldKeys.forEach((key) => {
        const vals = this.items.map((item) => item[key]);

        // Cheap and efficient unique.
        options[key] = Array.from(new Set(vals));
      });

      return options;
    },
  },
  async mounted() {
    await AdminServices.getApplicationsList().then((response) => {
      ////console.log(response);

      for (var i in response) {
        var gamesInterestedIn = "";

        for (var j in response[i].interestedIn) {
          gamesInterestedIn += response[i].interestedIn[j] + ", ";
        }

        var status = "";
        if (response[i].status == "approved") {
          status = "Approved";
        } else if (response[i].status == "rejected") {
          status = "Rejected";
        } else if (response[i].status == "Migrated") {
          status = "Migrated";
        } else if (response[i].status == "pending interview") {
          status = "Pending Interview";
        } else if (response[i].status == "submitted") {
          status = "New";
        } else {
          status = "Unknown";
        }

        if (
          response[i].lastmodifiedBy == null &&
          response[i].status == "approved"
        ) {
          response[i].lastmodifiedBy = "Migrated";
        }

        this.items.push({
          createdAt: response[i].createdAt,
          fullName: response[i].fullName,
          discordUsername: response[i].discordUsername,
          email: response[i].email,
          interestedIn: gamesInterestedIn,
          status: status,
          handledBy: response[i].lastmodifiedBy || "Not handled yet",
          data: response[i],
        });
      }

      this.totalRows = response.length;
    });

    this.isLoading = false;
  },
  methods: {
    datesSorter(a, b) {
      return Date.parse(a) - Date.parse(b);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshTable() {},
    openApplicationBtn(useruuid) {
      this.$router.push({
        name: "show-user",
        params: { useruuid: useruuid },
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },

    bulkUpdateApplicationStatus() {
      var status = this.bulk.applicationStatus.status;

      if (status == null) {
        this.$bvToast.toast("Please select a status", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      // Display a confirmation modal
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to update the status of the selected applications? Warning, this simply updates the status, you will still have to enter some information manually.",
          {
            title: "Please Confirm",
            size: "lg",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "primary",
            footerClass: "p-2",
            hideHeaderClose: true,
          }
        )
        .then((value) => {
          if (value) {
            console.log(value);
          }
        });
    },

    async bulkNotifiaction() {
      var agentDiscordId = this.$store.state.auth.user.discordId;
      var notificationType = this.bulk.discordNotifications.notification;
      var customMessage = this.bulk.discordNotifications.customMessage;
      var bulkUsers = this.selected;

      if (notificationType == null) {
        this.$bvToast.toast("Please select a notification type", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      if (notificationType == "custom" && customMessage == null) {
        this.$bvToast.toast("Please enter a custom message", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      for (var i in bulkUsers) {
        this.bulk.bulkStatus.push({
          discordUsername: bulkUsers[i].discordUsername,
          notification: "Interview Reminder",
          status: "pending",
        });
      }

      this.$bvModal.hide("bulkUpdatesModal");
      this.$bvModal.show("bulkUpdatesStatusModal");

      for (var y in bulkUsers) {
        var data = bulkUsers[y];
        var currentCount = y;
        var useruuid = data.data.useruuid || null;

        if (useruuid == null) {
          this.bulk.bulkStatus[currentCount].status = "error";
          this.bulk.bulkStatus[currentCount].error =
            "User does not have a useruuid";
          continue;
        }

        await BulkAdmin.BulkLillyNotification(
          useruuid,
          agentDiscordId,
          "interview_reminder",
          null
        ).then((response) => {
          if (response.success) {
            this.bulk.bulkStatus[currentCount].status = "success";
          } else {
            this.bulk.bulkStatus[currentCount].status = "error";
            this.bulk.bulkStatus[currentCount].error = response.error;
          }
        });

        // wait 500ms before sending the next notification
        await new Promise((r) => setTimeout(r, 500));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.approved {
  color: green;
}
</style>
