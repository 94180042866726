import axios from "axios";
import authHeader from "./auth-header";
//const API_URL = 'http://localhost:8081/';
const API_URL = "https://api-unp.sacul.cloud/NeK9NFKk/admin/";

class BulkAdminServices {
  async BulkLillyNotification(
    useruuid,
    agentDiscordId,
    notificationType,
    customMessage
  ) {
    var params = {
      useruuid: useruuid,
      agentDiscordId: agentDiscordId,
      notificationType: notificationType,
      customMessage: customMessage,
    };
    try {
      const response = await axios.post(
        API_URL + "discord/bulkLillyNotification",
        params,
        {
          headers: authHeader(),
        }
      );
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error };
    }
  }
}

export default new BulkAdminServices();
