<template>
  <b-row class="mb-32">
    <b-col cols="12" class="mb-32">
      <h3>Members 👥</h3>
      <p class="hp-p1-body mb-0">
        All users are displayed here, you can manage them by clicking on "Open".
      </p>

      <div class="divider my-12"></div>

      <b-row class="mt-32">
        <b-col cols="12">
          <ApplicationTable />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import ApplicationTable from "./widgets/ApplicationTable.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    ApplicationTable,
  },
};
</script>
